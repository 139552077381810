// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SNOWTRACE_API: "https://api-testnet.snowtrace.io/api",
  SNOWTRACE_GET_LOGS: "getLogs",
  BUY_ABI:
    "event Buy(address indexed,address,uint256,uint256,address,uint256,string)",
  ALL_NFT_MODE: 0,
  MY_NFT_MODE: 1,
  BUY_NFT_MODE: 2,
  color: ["#504C4C", "#918787", "#C7C2C0", "#E5DFDD"],
  priceFilter: [
    {
      id: 0,
      text: "< 0.1 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 1,
      text: "0.1 - 0.5 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 2,
      text: "0.5 - 1.0 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 3,
      text: "1.0 - 10 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 4,
      text: "10 - 100 AVAX",
      selected: false,
      key: "price",
    },
    {
      id: 5,
      text: "> 100 AVAX",
      selected: false,
      key: "price",
    },
  ],
  CARDS_PER_PAGE: 12,
  MYCARDS_PER_PAGE: 100,
  CARDS_DISPLAY_MIN_LOADED: 4,
  MAX_TOP_LIST: 3,
  faq: [
    {
      question: "Why did red�dev create the NFT Art Project?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.",
    },
    {
      question: "How does the NFT Art Project innovate in the NFT Art space?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.",
    },
    {
      question:
        "What if I�d like to fork the code? Is NFT Art Project open source?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.",
    },
    {
      question: "I am an artist, and I would like to participate. How can I?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.",
    },
    {
      question: "I am a fundraiser at an NGO. How can we participate?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.",
    },
    {
      question: "I have having technical difficulties. What should I do?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.",
    },
    {
      question:
        "Can NFTs minted here be exhibited and sold on other platforms such as Kalao?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.",
    },
  ],
  BUY_TITLE: "Turkiye Earthquake Relief NFTs",
  PATTERN: "Portrait",
  PATTERN_COLOR: "Pattern Color",
  LAYER_CHOICE: "Choice",
  PICK_DISTRO: "How would you like the proceeds to be distributed?",
  DISTRIBUTION: "Funds Distribution",
  DIST: "Distribution",
  MY_DISTRO: "My Distro",
  BACK_TO_PRESET: "Back to Alter-Egos",
  BACKGROUND_COLOR: "Donation",
  NAME: "Name",
  ALTER_EGO: "Alter-Ego",
  FINAL_CHECK: "Final Check",
  FINAL_TEXT: "Final Check Before Minting",
  TWITTER_API: "https://twitter.com/intent/tweet?",
  NEW_NAME: "My New NFT",
  MINT_TITLE: "Mint a NFT",
  MY_NFT: "My NFT",
  HERO_DESC:
    "Turkiye is struggling with the earthquake disaster and we’re calling for your help!",
  TOP_PAGE_DESC:
    "These three AHBAP NGO have raised the most funds for Earthquake Relief Fund in Turkiye. Each time someone buys one, more money is raised. Would you like to own one and help the victims of the earthquake in Turkey?",
  HOW_UNIQUE_DESC:
    "MUAFNFT, a curatorial contemporary art project on Avalanche, is starting an aid campaign for the victims of the earthquake in Turkey through the artist Can Köylüoğlu and Fund Raisin.",
  HOW_UNIQUE_MINT:
    "On February 6, Turkiye experienced a significant shock with two major earthquakes. The intensity of the earthquakes in Turkiye was 7.8 and 7.6. This earthquake directly affected approximately 13.5 million people and left them homeless. The people who survived the earthquake that occurred during the night hours when sleep was at its deepest had to contend with harsh winter conditions. In total, 105,000 buildings were damaged or destroyed in an uninhabitable way. More than 40,000 people have sadly lost their lives so far. After this devastating disaster, some migrated, while others had to live in tent cities. In total, 2 million people emigrated.<br><br> To explain the situation for people who do not live in Turkiye to understand better; Imagine that two earthquakes of magnitude 7.8 and 7.6 occurred in the area between Amsterdam and Paris. With all these, the cost of the earthquake to Turkiye is more than 84 billion dollars.",
  HOW_UNIQUE_RARE:
    "Your donations will be transferred directly to the crypto wallet of AHBAP, a non-profit and non-governmental organization operating in Turkiye. <br><br>AHBAP Association aims to provide all kinds of aid to the needy people, in kind and in cash, to strengthen the awareness of cooperation in the society, to serve in the construction of good people and good society, to create contemporary and sustainable cooperation networks with new projects, to contribute to the development of the culture and its development with today's technological opportunities and to the future. was established to provide",
  HOW_UNIQUE_SUPPORT:
    "Now we are working with the Avalanche community, in collaboration with Fund Raisin, to ensure that aid is sustainable, helping people in the long run. Every NFT purchased will be transferred directly to the AHBAP organization's crypto wallet. Now is the time of solidarity for Turkiye!<br><br> <i>For the transparency of the information contained in the contract: <br><br>97% of the donations will be transferred to the beneficiary, and 3% will be used for financing the volunteer developer team and for the sustainability of the Fund Raisin project.</i>",
  HOW_UNIQUE_GREEN:
    'The artist stands out with his dynamic compositions, the colors he uses and the concepts he deals with. His works are fed as much as possible from daily life and issues that are reluctant to talk about. Although this process changes from time to time, the starting point of his works is the concept of "violence" and "disaster" as he frequently witnesses and is exposed to in daily life.',
  WAGMI_DESC:
    "The Earthquake Relief Fund in Turkey project, sponsored by Fund Raisin and in cooperation with MUAFNFT, was established to transfer crypto money to the non-governmental organization AHBAP and was implemented to help the disaster victims in the earthquake area. The income from NFT sales is directly transferred to the AHBAP Non-Governmental Organization. MUAFNFT and its artists have pledged that they will not gain any personal benefit from this social project through codes.",
  INSTAGRAM_LINK: "https://www.instagram.com/galerimuaf/",
  INSTAGRAM_LINK_TOOLTIP: "MUAFNFT on Instagram",
  INSTAGRAM_LINK_2: "https://www.instagram.com/cankoyluoglu/",
  INSTAGRAM_LINK_2_TOOLTIP: "Can Köylüoğlu on Instagram",
  DISCORD_LINK: "https://discord.gg/R9g6Jjy7TW",
  TWITTER_LINK: "https://twitter.com/muafnft",
  TWITTER_LINK_TOOLTIP: "MUAFNFT on Twitter",
  NFT_LICENSE_LINK:
    "https://github.com/a16z/a16z-contracts/blob/master/arweave_upload/licenses/PERSONAL.pdf",
    NFT_LICENSE_NAME: "Can't Be Evil Personal License"
};
