import React from "react";
import icon1 from "../../assets/images/how_they_are_unique_icons/icon1.svg";
import icon2 from "../../assets/images/how_they_are_unique_icons/icon2.svg";
import icon3 from "../../assets/images/how_they_are_unique_icons/icon3.svg";
import icon4 from "../../assets/images/how_they_are_unique_icons/icon4.svg";
import { useState } from "react";
import Modal from "../Modal/Modal";
import Popup from "../Modal/Popup";
import HowUniquePopup from "../Modal/HowUniquePopup";
import { NavLink } from "react-router-dom";
import constant from "../../utils/constant";

const howUniqueCards = [
  {
    id: 0,
    icon: icon1,
    title: "The Earthquake's Impact",
    desc: constant.HOW_UNIQUE_MINT,
  },
  {
    id: 1,
    icon: icon2,
    title: "Your donations to AHBAP",
    link: "https://ahbap.org/",
    desc: constant.HOW_UNIQUE_RARE,
  },
  {
    id: 2,
    icon: icon3,
    title: `Joining with the Avalanche Community`,
    desc: constant.HOW_UNIQUE_SUPPORT,
  },
  {
    id: 3,
    icon: icon4,
    title: "Artist Can Köylüoğlu",
    desc: constant.HOW_UNIQUE_GREEN,
  },
];
const HowUnique = () => {
  const [show, setShow] = useState(false);
  const [cardId, setCardId] = useState(0);

  const openModal = (index) => {
    setCardId(index);
    setShow(!show);
  };

  const closeModal = () => {
    setShow(!show);
  };

  return (
    <>
      <span className="link_wrapper" id="about"></span>
      <section className="how-unique default-settings gradient-bg">
        <h1 className="how-unique__title title-ds">About</h1>
        <h1 className="how-unique__title title-mb">About</h1>
        <div className="hr-line"></div>
        <p className="how-unique__desc">{constant.HOW_UNIQUE_DESC}</p>
        <div className="how-unique__cards">
          {howUniqueCards.map((item) => (
            <div
              key={item.id}
              className="how-unique__card"
              onClick={() => openModal(item.id)}
            >
              <div className="how-unique__card-icon-box">
                <img
                  className={`how-unique__card-icon icon${item.id + 1}`}
                  src={item.icon}
                  alt="icon"
                ></img>
              </div>
              <h1 className="how-unique__card-title">{item.title}</h1>
              <div className="how-unique__card-btn">
                <span className="how-unique__card-btn-text">Learn More</span>
              </div>
            </div>
          ))}
        </div>
        {/*
        <NavLink to="/faqs" className="faqs-link-wrapper">
          <h3 className="faqs-link">More Answers to Common Questions</h3>
        </NavLink> */}
        <Modal show={show}>
          <Popup>
            <HowUniquePopup
              item={howUniqueCards[cardId]}
              onClose={closeModal}
            />
          </Popup>
        </Modal>
      </section>
    </>
  );
};

export default HowUnique;
