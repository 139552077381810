import React from "react";
import heart_icon from "../../assets/images/heart_icon.svg";
import constant from "../../utils/constant";
import heart_hand_shake_dark from "../../assets/images/heart_hand_shake_dark.svg";

const Wagmi = () => {
  return (
    <div id="wagmi" className="default-settings">
      <div className="wagmi__title-box">
        <img
          className="wagmi__title-heart-icon"
          src={heart_icon}
          alt="Turkiye Relief"
        ></img>
        <h1 className="wagmi__title-text plus-sign">+</h1>
        <img
          className="wagmi__title-nft-icon"
          src={heart_hand_shake_dark}
          alt="Turkiye Relief"
        ></img>
        <h1 className="wagmi__title-text plus-sign">+</h1>
        <h1 className="wagmi__title-text">U</h1>
      </div>

      <p className="wagmi__desc">
        {constant.WAGMI_DESC} NFTs are licensed under the
        <a
          href={constant.NFT_LICENSE_LINK}
          target="_blank"
          rel="noreferrer"
          style={{ color: "inherit" }}
        >
          {" "}
          {constant.NFT_LICENSE_NAME}
        </a>
      </p>
    </div>
  );
};

export default Wagmi;
